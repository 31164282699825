import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { ConfirmationModal } from 'component/common/Modal/ConfirmationModal'
import { Loader } from 'component/common/Loader/Loader'
// import DeleteIcon from '@mui/icons-material/Delete';
import { fetchBatches, fetchBatchPackageDetails, resequenceBatch, updateBatch, destroyBatch, generateRoute } from 'features/batch/batchAction'
import { fetchRouteSummary } from 'features/route/routeAction'
import { Layout } from 'component/common/Layout/Layout';
import { CardDetail } from 'component/dashboard/cardDetail'
import { Tooltip, Link } from '@mui/material';
import { PrimaryButton } from 'component/common/Button/Button'
import { getDateWithTimeStamp, covertToString, iconStyle, convertDateRangeToUTC } from 'utils/common'
import { BatchMapView } from './batchMapView'
import { BatchMoveModal } from './batchMoveModal'
import { CreateBatch } from './createBatchModal'
import { resetSuccessState as batchResetSuccessState } from 'features/batch/batchSlice'
import { resetSuccessState as routeResetSuccessState } from 'features/route/routeSlice';
// import CheckIcon from '@mui/icons-material/Check';
import { TableHeader } from 'component/dataTable/tableHeader';
import 'component/userManagement/userAccount/UserAccount.scss'
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { Audit } from 'component/audit/audit'
import { TableFilter } from 'component/dataTable/tableFilter';
import { getCurrentDateForFilter, checkPermission, getGlobalFilter, getUserTimeZone, getUserDCOption } from 'utils/common'
import { validateForm, validateStartAndEndDate } from 'utils/formValidator'
import { Tab, TabWrapper } from 'component/common/Tab/Tab'
import { DeliveryStatusFilter } from 'component/packages/DeliveryStatusFilter'
import { DeliveryStatusCellRenderer } from 'component/packages/deliveryStatusCellRenderer'
import { setPackageFilter } from 'features/packages/packagesSlice'
import { RescheduleRoute } from 'component/route/RescheduleRouteModal'
import { checkForInProgressOrFailedBatch, getTabNames, batchTabEnum } from 'utils/batchHelper'
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';
import ReplayIcon from '@mui/icons-material/Replay';
import LoopIcon from '@mui/icons-material/Loop';
import { UpdateBatchStatus } from './updateBatchStatus';
import { getRouteSummary } from 'utils/routeHelper'
import { Title } from 'component/common/commonStyles';
import { resetError } from 'features/route/routeSlice';
import {  getDeliveryStatusValue } from 'utils/packageHelper'

const date = getCurrentDateForFilter()
const formInitialState = {
    // batchStatus: '',
    startDate: date,
    endDate: date,
    batchId: '',
    batchName: ''
}

export const Batches = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const batchId = queryParams.get('batchId');
    const batchStatus = queryParams.get('batchStatus');

    const { batches, error : batchError, loading, batchSuccess, bacthPackageDetails, batchCount, successMessage } = useSelector(
        (state) => state?.batches
    )
    const {error : routeError} = useSelector(
        (state) => state?.route
    )
    const { rescheduleRouteSuccess, loading:routeLoading, routeSummmary } = useSelector(
        (state) => state?.route
    )

    const { isMobile, isIpad } = useSelector(
        (state) => state?.viewport
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { highPriority } = useSelector(
        (state) => state?.deliveryStatusManagement
    )
    const [modalProps, setModalProps] = useState(null)
    const [showMap, setShowMap] = useState(false)
    const [showBatchMoveModal, setShowBatchMoveModal] = useState(false)
    const [selectedPackageIds, setSelectedPackageIds] = useState([]);
    const [showBatchCreateModal, setShowBatchCreateModal] = useState(false)
    const [selectedBatchIds, setSelectedBacthIds] = useState([]);
    const [confirmationModalProps, setConfirmationModalProps] = useState(null);
    const [sorteData, setSortedData] = useState(batches);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [page, setPage] = useState(0);
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [applyFilter, setApplyFilter] = useState(false);
    const [formError, setFormErrors] = useState({});
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedTab, setSelectedTab] = React.useState(batchTabEnum?.ACCEPT_BATCH);
    const selectedTabRef = useRef();
    const [resetSelectedRows, setResetSelectedRows] = useState(false)
    const [operationType, setOperationType] = useState('create')

    const [rescheduleBatch, setRescheduleBatch] = useState(null)
    const [rescheduleRouteModalOpen, setRescheduleRouteModalOpen] = useState(false)
    const hasEditPermission = checkPermission(user, 'BATCH')
    const userTimeZone = getUserTimeZone(user?.distributionCenterResponseVm?.timeZone) || 'America/Toronto'
    const batchSummaryData = getRouteSummary(routeSummmary, 'BATCHES')
    const tabs = getTabNames(batchSummaryData)

    const fetchBatchesData = () => {
        const globalFilter = getGlobalFilter(highPriority)
        const { startDate, endDate } = formValues
        const dateFilter = convertDateRangeToUTC(startDate, endDate, "YYYY-MM-DD")

        const data = {}
        if (formValues?.batchId) {
            data['batchId'] = formValues?.batchId?.trim() || null
        }
        if (formValues?.batchName || batchId) {
            data['batchName'] = formValues?.batchName?.trim()
        }

        data['widget'] = selectedTab

        dispatch(fetchBatches({
            page: page,
            size: rowsPerPage,
            ...data,
            ...globalFilter,
            ...dateFilter,
            dcName: getUserDCOption(user)?.value
        }))
        dispatch(fetchRouteSummary({
            widget: 'BATCH',
            ...dateFilter,
            ...globalFilter,
            dcName: getUserDCOption(user)?.value
        }))
    }

    useEffect(() => {
        return () => {
            dispatch(batchResetSuccessState())
        };
    }, [])



    useEffect(() => {
        if (batchId) {
            setSelectedTab(batchStatus)
            setFormValues((prevValues) => ({
                ...prevValues,
                'batchId': batchId,
                startDate: '',
                endDate: ''
            }));
            setApplyFilter(true)
        }
    }, [batchId])

    useEffect(() => {
        fetchBatchesData()
    }, [page, applyFilter, rowsPerPage, selectedTab, highPriority])

    useEffect(() => {
        if (showMap) {
            dispatch(fetchBatchPackageDetails({
                batchIds: selectedBatchIds?.join(',')
            }))
        }
    }, [showMap])

    useEffect(() => {
        if (batchError || routeError) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: batchError || routeError,
                    open: true,
                    type: 'error'
                }
            )
        }
        dispatch(resetError())
    }, [batchError, routeError]);

    useEffect(() => {
        //If user is on last page and deleted all records from that page, then page should be reset to page-1
        const pageCount = Math.ceil(batchCount / 10)
        if (batchCount && (page > 1) && (pageCount < page)) {
            setPage(page - 1)
        }
    }, [batchCount])

    useEffect(() => {
        if (batchSuccess || rescheduleRouteSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: successMessage ? successMessage : 'Batch update successfully done!',
                    open: true,
                    type: 'success'
                }
            )
            setResetSelectedRows(true)
            const redirectTimer = setTimeout(() => {
                setModalProps(null)
                setSelectedBacthIds([])
                setConfirmationModalProps(null)
                dispatch(batchResetSuccessState())
                fetchBatchesData()
                setResetSelectedRows(false)
                if (rescheduleRouteSuccess) {
                    setRescheduleRouteModalOpen()
                }
                dispatch(routeResetSuccessState())
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [batchSuccess || rescheduleRouteSuccess]);

    const handleBacthSelect = (batches) => {
        //Selected batch ids should not reset if user is on map view because user may select few more packages to move
        if (!showMap) {
            setSelectedBacthIds(batches);
        }
    };

    const handleRescheduleRouteClick = (batch = null) => {
        setRescheduleBatch(batch)
        setRescheduleRouteModalOpen(!rescheduleRouteModalOpen)
    }

    const handleApplyFilterClick = () => {
        // const notRequiredFields = ['startDate', 'endDate', 'batchName', 'batchId', 'batchStatus']
        const notRequiredFields = ['startDate', 'endDate', 'batchName', 'batchId']
        const errors = validateForm(formValues, notRequiredFields);
        const dateErrors = validateStartAndEndDate(formValues?.startDate, formValues?.endDate);
        if (Object.keys(errors).length || Object.keys(dateErrors).length) {
            setFormErrors(Object.keys(errors).length ? errors : dateErrors)
        }
        else {
            setFormErrors({})
            setApplyFilter(!applyFilter)
            setPage(0)
        }
    }

    const handleMoveButtonClick = () => {
        setShowBatchMoveModal(!showBatchMoveModal)
    }

    const handleShowOnMap = () => {
        if (showMap) {
            // Reset selected packages and selected ids
            setSelectedPackageIds([])
            setSelectedBacthIds([])
            setShowMap(!showMap)
        }
        else {
            // Check if selected batches have any batch in inprogress status, if yes show popup and call api
            if (selectedBatchIds) {
                const { inProgressBatchIds } = checkForInProgressOrFailedBatch(batches, selectedBatchIds)
                if (inProgressBatchIds?.length) {
                    setModalProps(
                        {
                            title: 'Show On Map',
                            message: 'Batches with In Progress status are not allowed',
                            open: true,
                            type: 'error'
                        }
                    )
                }
                else {
                    setShowMap(!showMap)
                }
            }
        }
    }

    const handleBatchCreate = (type) => {
        setOperationType(type)
        setShowBatchCreateModal(!showBatchCreateModal)
        if (type === 'move') {
            dispatch(fetchBatchPackageDetails({
                batchIds: selectedBatchIds?.join(',')
            }))
        }
    }

    const handleFinaliseBatchClick = () => {
        if (selectedBatchIds) {
            dispatch(updateBatch({ batchIds: selectedBatchIds, status: 'BATCH_SUPERVISOR_ACCEPTED' }))
        }
    }

    const handleGenerateRoute = () => {
        if (selectedBatchIds) {
            dispatch(generateRoute(selectedBatchIds))
        }
    }

    const handleCreateRoute = () => {
        if (selectedBatchIds) {
            dispatch(updateBatch({ batchIds: selectedBatchIds, status: 'ROUTE_CREATED' }))
        }
    }

    const handleMarkerClick = (packageId) => {
        setSelectedPackageIds(packageId)
    };

    const handleRequenceBatchClick = (batchId) => {
        const selectedBatches = batchId ? [batchId] : selectedBatchIds
        dispatch(resequenceBatch(selectedBatches))
    }

    const handleAcceptBatchClick = (batchId) => {
        const selectedBatches = batchId ? [batchId] : selectedBatchIds
        // Check if selected batches have any batch in inprogress status, if yes show popup and call api
        const { inProgressBatchIds, allowedBatchIds } = checkForInProgressOrFailedBatch(batches, selectedBatches)
        if (selectedBatches) {
            if (inProgressBatchIds?.length) {
                setModalProps(
                    {
                        title: 'Accept Batch',
                        message: 'Batches with In Progress or Failed status will not be consider during this action',
                        open: true,
                        type: 'success'
                    }
                )
                const redirectTimer = setTimeout(() => {
                    setModalProps(null)
                    if (allowedBatchIds?.length) {
                        dispatch(updateBatch({ batchIds: allowedBatchIds, status: 'BATCH_MANUAL_CREATED' }))
                    }
                }, 3000);
                return () => {
                    clearTimeout(redirectTimer);
                }
            }
            else {
                if (allowedBatchIds?.length) {
                    dispatch(updateBatch({ batchIds: allowedBatchIds, status: 'BATCH_MANUAL_CREATED' }))
                }
            }
        }
    }

    // const handleConfirmationClose = () => {
    //     setConfirmationModalProps(null)
    // }

    const handleRejectBatch = (batchId) => {
        const selectedBatches = batchId ? [batchId] : selectedBatchIds
        if (selectedBatches) {
            dispatch(updateBatch({ batchIds: selectedBatches, status: 'BATCH_REJECTED' }))
        }
    }

    const handleDestroyBatch = (batchId) => {
        const selectedBatches = batchId ? [batchId] : selectedBatchIds
        if (selectedBatches) {
            dispatch(destroyBatch(selectedBatches))
        }
    }

    const handlePageChange = (event, page) => {
        setPage(page)
        setResetSelectedRows(false)
        if (selectedBatchIds?.length) {
            setResetSelectedRows(true)
            setSelectedBacthIds([])
        }
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleResetFilter = () => {
        setFormValues({
            // batchStatus: '',
            startDate: '',
            endDate: '',
            batchId: '',
            batchName: ''
        })
    }

    const handleTabChange = (event, value) => {
        selectedTabRef.current = value
        setSelectedTab(value)
        setResetSelectedRows(!resetSelectedRows)
    }

    const handleNavigation = (params) => {
        const { ...rest } = params
        dispatch(setPackageFilter(rest))
        navigate(urls?.PARCEL_WIDGET_URL)
    }

    const handleModelClose = () => {
        setModalProps(null)
        dispatch(resetError())
    }

    const columns = [
        {
            accessor: "batchId",
            title: "Batch ID",
            width: "2rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.batchId || '--'
        },
        {
            accessor: "routeName",
            title: "Batch Name",
            width: "8rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.routeName || '--'
        },
        {
            accessor: 'totalPackages',
            title: "Total Parcels",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.totalPackages ? <Link id='routetId' style={{ cursor: 'pointer' }} sx={{ textDecoration: 'none' }} onClick={() => handleNavigation({
                routeId: original?.batchId
            })}>{original?.totalPackages}</Link> : original?.totalPackages,
            render: (item) => item?.totalPackages
        },
        {
            accessor: 'totalStops',
            title: "Total Stops",
            width: "2rem",
            Cell: ({ cell: { value } }) => value,
            render: (item) => item?.totalStops
        },
        {
            accessor: 'fsaZoneNames',
            title: "FSA Zone",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.fsaZoneNames || '--',
        },
        {
            accessor: 'deliveryStatus',
            title: "Priority",
            width: "5rem",
            Cell: ({ cell: { row: { original } } }) => <DeliveryStatusCellRenderer key={original?.batchId} isHighPriority={original?.highPriorityCount}/>,
            render: (item) => getDeliveryStatusValue(item?.highPriorityCount),
            isSortable: false
        },
        {
            accessor: 'dailyRouteStatus',
            title: "Batch Status",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? covertToString(value) : '--',
            render: (item) => item?.dailyRouteStatus ? covertToString(item?.dailyRouteStatus) : '--'
        },
        selectedTabRef?.current === batchTabEnum?.REJECT_BATCH && {
            accessor: 'rejectedFrom',
            title: "Rejected From",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? covertToString(value) : '--',
            render: (item) => item?.rejectedFrom ? covertToString(item?.rejectedFrom ) : '--'
        },
        {
            accessor: 'routeServiceTimeInHr',
            title: "Total Route Time",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.routeServiceTimeInHr || '--',
            isSortable: false
        },
        {
            accessor: 'routeSequencingTime',
            title: "Route Sequencing Time",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.routeSequencingTime || '--'
        },
        {
            accessor: 'routeStartDate',
            title: "Route Start Date",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
            render: (item) => item?.createDate ? getDateWithTimeStamp(item?.routeStartDate, user) : '--'
        },
        {
            accessor: 'createDate',
            title: "Created Date",
            width: "3rem",
            Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
            render: (item) => item?.createDate ? getDateWithTimeStamp(item?.createDate, user) : '--'
        },
        hasEditPermission && {
            accessor: 'action',
            title: "Action",
            width: "2rem",
            isSortable: false,
            Cell: ({ cell: { row: { original } } }) =>
                <div className='action'>
                    {/* {(!selectedTabRef?.current || selectedTabRef?.current === batchTabEnum?.ACCEPT_BATCH) &&
                        <Tooltip title="Finalise"><CheckIcon color={isDisabled('accept', original?.dailyRouteStatus) ? 'disabled' : '#5D48FF'} fontSize="small" disabled={isDisabled('accept', original?.dailyRouteStatus)} onClick={() => !isDisabled('accept', original?.dailyRouteStatus) && handleAcceptBatchClick(original?.batchId)} /></Tooltip>
                    }
                    {selectedTabRef?.current === batchTabEnum?.REJECT_BATCH &&
                        <Tooltip title="Delete"><DeleteIcon color={isDisabled('reject', original?.dailyRouteStatus) ? 'disabled' : '#5D48FF'} fontSize="small" onClick={() => !isDisabled('reject', original?.dailyRouteStatus) && handleRejectBatchClick(original?.batchId)} /></Tooltip>
                    } */}
                    {selectedTabRef?.current === batchTabEnum?.FINALISE_BATCH ?
                        <Tooltip title="Reschedule"><LoopIcon style={iconStyle} fontSize="small" onClick={() => handleRescheduleRouteClick(original?.batchId)} /></Tooltip> : null
                    }
                    <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.batchId, 'entityType': 'batch' }))} /></Tooltip>
                </div>
        }
    ]?.filter(Boolean);

    const tableFilterProps = {
        fields: [
            {
                label: 'Batch Id',
                value: formValues?.batchId,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '11rem',
                name: 'batchId',
                placeholder: 'Batch Id',
                error: formError?.batchId
            },
            {
                label: 'Batch Name',
                value: formValues?.batchName,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '11rem',
                name: 'batchName',
                placeholder: 'Batch Name'
            },
            {
                label: 'Start Date',
                value: formValues?.startDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '11rem',
                name: 'startDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.startDate,
                max: new Date()?.toISOString()?.split('T')?.[0]
            },
            {
                label: 'End Date',
                value: formValues?.endDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '11rem',
                name: 'endDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.endDate,
                max: new Date()?.toISOString()?.split('T')?.[0]
            }],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px'
    }

    return (
        <Layout headerTitle={'Batches'} globalFilter={!showMap ? <DeliveryStatusFilter showSLA={false}/> : null} showBackArrow={false}
            action={
                hasEditPermission ? <>
                    <Title width={!isMobile && '250px'}>{batchSummaryData?.PACKAGE_TO_BATCH}</Title>
                    {showMap ?
                        <>
                            {selectedBatchIds?.length > 1 ? <div><PrimaryButton type="button" label={'Update'} height={'30px'} onClick={handleMoveButtonClick} disabled={!selectedPackageIds?.length} /> </div> : null}
                            <div><PrimaryButton className='buton-primary' type="button" label={'Back To Summary'} height={'30px'} padding={isMobile || isIpad ? '11px 15px' : null} onClick={handleShowOnMap} /></div>
                        </> :
                        <>
                            <div><PrimaryButton type="button" label={isMobile ? 'Create' : 'Create Batch'} height={'30px'} onClick={() => handleBatchCreate('create')} padding={'11px 15px'} />
                            </div>
                            <Tooltip title={selectedBatchIds?.length ? 'Select max 5 batches' : 'Select Batches'}><div><PrimaryButton className='buton-primary' type="button" label={'Show on Map'} height={'30px'} onClick={() => selectedBatchIds?.length && selectedBatchIds?.length <= 5 && handleShowOnMap()} padding={'11px 15px'} /></div></Tooltip>
                        </>
                    }
                </> : null
            }>
            {!showMap ?
                <TabWrapper>
                    <Tab tabs={tabs} handleChange={handleTabChange} value={selectedTab} />
                    {hasEditPermission ? <Tooltip title={selectedTab === batchTabEnum?.INPROGRESS_BATCH && selectedBatchIds?.length > 15 ? 'Select max 15 batches' : ''}> <UpdateBatchStatus selectedTab={selectedTab} dataTestId="ApplyTabButton" handleFinaliseBatchClick={handleFinaliseBatchClick} handleAcceptBatchClick={handleAcceptBatchClick} handleRejectBatch={handleRejectBatch} disabled={!selectedBatchIds?.length || (selectedTab === batchTabEnum?.INPROGRESS_BATCH && selectedBatchIds?.length > 15)} handleGenerateRoute={handleGenerateRoute} handleDestroyBatch={handleDestroyBatch} handleCreateRoute={handleCreateRoute} handleRequenceBatchClick={handleRequenceBatchClick} handleBatchCreate={handleBatchCreate} selectedBatchIds={selectedBatchIds} /> </Tooltip> : null}
                </TabWrapper> : null}

            <CardDetail title={'Batches'} showBackArrow={false}>
                {showMap && bacthPackageDetails ? <BatchMapView data={bacthPackageDetails} selectedPackageIds={selectedPackageIds} handleMarkerClick={handleMarkerClick} /> : <div className='container'>
                    <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Batches' onRefreshClick={handleApplyFilterClick} />
                    <TableFilter {...tableFilterProps} />
                    <div className='content'>
                        <DataTable columns={columns} data={batches || []} isCollpsable={false} onPageChange={handlePageChange} page={page} totalRowsCount={batchCount} showPagination={batches?.length ? true : false} onSortChange={setSortedData} uniqueKey={'batchId'} setUpdatedColumns={setUpdatedColumns}
                            handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} allowSelect={selectedTab === batchTabEnum?.INPROGRESS_BATCH ? false : true} onSelectClick={handleBacthSelect} resetSelectedRows={resetSelectedRows} />
                    </div>
                </div>}
                {showBatchMoveModal ? <BatchMoveModal isModelOpen={showBatchMoveModal} handleClose={handleMoveButtonClick} batches={bacthPackageDetails} selectedPackages={selectedPackageIds} resetSelectedPackages={() => setSelectedPackageIds([])} handleShowOnMap={handleShowOnMap} fetchBatchesData={fetchBatchesData} userTimeZone={userTimeZone} /> : null}
                {showBatchCreateModal ? <CreateBatch userTimeZone={userTimeZone} isModelOpen={showBatchCreateModal} handleClose={handleBatchCreate} selectedBatchIds={selectedBatchIds} type={operationType} /> : null}
                {modalProps ? <ResponseModal {...modalProps} handleClose={handleModelClose} /> : null}
                {confirmationModalProps ? <ConfirmationModal {...confirmationModalProps} /> : null}
                {loading || routeLoading ? <Loader isLoading={loading || routeLoading} /> : null}
                {rescheduleRouteModalOpen ? <RescheduleRoute isModelOpen={rescheduleRouteModalOpen} handleClose={handleRescheduleRouteClick} route={rescheduleBatch} user={user} tabName={'Reschedule_Batch'} /> : null}
            </CardDetail>
            <Audit />
        </Layout>
    )
}